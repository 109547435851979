<script lang="ts">
import { createEventDispatcher } from 'svelte'

export let showArrow: boolean
export let open: boolean

const dispatch = createEventDispatcher()
</script>

<button on:click={() => dispatch('click')}>
  <slot />
  {#if showArrow}
    <svg
      width="10"
      height="15"
      viewBox="0 0 10 15"
      class:rotated={open}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.45605 1.10889L7.91225 7.56508L1.45605 14.0213"
        stroke={open ? '#FF001F' : '#FFF'}
        stroke-width="2" />
    </svg>
  {/if}
</button>

<style lang="scss">
button {
  color: #fff;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  min-height: 72px;
  cursor: pointer;
  font-size: 23px;
  padding: 0 26px;
  border-bottom: 1px solid rgba(90, 88, 107, 0.6);
  margin: 0;

  :active {
    background-color: transparent;
  }
}

svg {
  position: absolute;
  right: 26px;
  transition: 0.2s;
  transform: rotate(0deg);
}

.rotated {
  transform: rotate(90deg);
}
</style>
