<script lang="ts">
import { browser } from '$app/environment'
import { page } from '$app/stores'
import Footer from '$components/Footer/Footer.svelte'
import DesktopNavigation from '$components/Navigation/DesktopNavigation.svelte'
import MobileNavigation from '$components/Navigation/MobileNavigation.svelte'
import { patchMetaProps } from '$lib/utils/metaProps'

$: if (browser) {
  if (['/contact-us'].includes($page.url.pathname)) {
    document.body.classList.add('contact-us-bg')
  } else if (['/platform'].includes($page.url.pathname)) {
    document.body.classList.add('platform-bg')
  } else {
    document.body.classList.remove('contact-us-bg')
    document.body.classList.remove('platform-bg')
  }
}
$: metaProps = patchMetaProps($page.data.metaProps)
</script>

<svelte:head>
  <title>{metaProps.title}</title>
  <meta name="description" content={metaProps.description} />
  <meta name="og:site_name" content="Lumiere" />
  <meta name="og:type" content={metaProps.type} />

  <meta name="og:title" content={metaProps.title} />
  <meta name="og:description" content={metaProps.description} />
  <meta name="og:url" content={metaProps.url} />
  <meta property="og:image" content={metaProps.image} />

  <meta name="twitter:title" content={metaProps.twitter_title} />
  <meta name="twitter:description" content={metaProps.twitter_description} />
  <meta property="twitter:image" content={metaProps.twitter_image} />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:site" content="@latitudelumiere" />
</svelte:head>

<DesktopNavigation />
<MobileNavigation />
<slot />

<Footer />
