<script lang="ts">
import { page } from '$app/stores'
import type { SvelteComponent } from 'svelte'
import MobileLinkContent from './MobileLinkContent.svelte'

export let nested: typeof SvelteComponent<any> | null | undefined = null
export let href: string = ''
export let showArrow = true
let openNested = false

page.subscribe(() => {
  openNested = false
})
</script>

<MobileLinkContent
  on:click={() =>
    nested ? (openNested = !openNested) : (location.href = href)}
  {showArrow}
  open={openNested}>
  <slot />
</MobileLinkContent>
{#if openNested}
  <svelte:component this={nested} />
{/if}
